@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", "Plus Jakarta Sans Placeholder", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #07020e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* src/GradientText.css */

.gradient-text {
  background-image: linear-gradient(
    296deg,
    rgb(255, 214, 255) 0%,
    rgba(255, 166, 166, 0.52) 44.8918%,
    rgb(107, 9, 107) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

/* src/index.css */
.text-gradient {
  background-image: linear-gradient(
    296deg,
    rgb(255, 214, 255) 0%,
    rgba(255, 166, 166, 0.52) 44.8918%,
    rgb(107, 9, 107) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

/* src/AboutSection.css */

.custom-border-container {
  --border-bottom-width: 1px;
  --border-color: #ff6eff;
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  align-content: center;
  align-items: center;
  border-radius: 16px;
  box-shadow: inset 0 2px 37px #ffabf14d;
  display: flex;
  flex: 1 0 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: flex-start;
  max-width: 1000px;
  overflow: hidden;
  padding: 40px;
  position: relative;
  will-change: var(--framer-will-change-override, transform);
  border: var(--border-top-width) var(--border-style) var(--border-color);
}

.description {
  line-height: 26px;
  letter-spacing: 0.01rem;
  font-size: 19px;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}

.about_div1.is-visible {
  opacity: 0;
  animation: fade-in-right ease 0.6s forwards;
  animation-delay: 0.3s;
}

.about_div2.is-visible {
  opacity: 0;
  animation: fade-in-right ease 0.6s forwards;
  animation-delay: 0.8s;
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes grow-left {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

/* src/FeaturesSection.css */

.gradient-text {
  background: linear-gradient(
    90deg,
    #742774,
    #8e3c8e,
    #a953a9,
    #c369c3,
    #dd7fdd
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.feature-card {
  transition: transform 0.3s ease-in-out;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feature-card.reveal {
  opacity: 1;
  transform: translateY(0);
  animation: reveal 0.6s ease-out;
}

.feature-icon {
  width: 240px;
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #ffbfff;
  margin-bottom: 1rem;
}

.feature-description {
  color: #666666;
  margin-bottom: 1rem;
  font-weight: 400;
}

.feature-button {
  border-radius: 0.5rem;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  --border-bottom-width: 1px;
  --border-color: #ff6eff;
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  align-content: center;
  align-items: center;
  box-shadow: inset 0 2px 20px #ffabf14d;
  display: flex;
  flex: 1 0 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  height: min-content;
  justify-content: flex-start;
  position: relative;
  will-change: var(--framer-will-change-override, transform);
  border: var(--border-top-width) var(--border-style) var(--border-color);
  transition: transform 0.2s; /* Animation */
}



.wide-button{
  max-height: 250px;
  min-height: 40px;
  width: 50%;
}

.feature-button:hover {
  transform: scale(1.5);
}

/* src/AiComponents  */
.aicomp-title {
  font-size: 15px;
  font-weight: 600;
  color: #ffbfff;
}

.aicomp-description {
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 12px;
}

.aicomp-slider {
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.aicomp-track {
  display: flex;
  gap: 1rem;
  animation: slide 20s linear infinite;
}

.aicomp-track:hover {
  animation-play-state: paused;
}

.aicomp-card {
  transition: transform 0.3s ease-in-out;
  border-radius: 20px;
  color: #fff;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  --border-bottom-width: 1px;
  --border-color: #ff6eff;
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  box-shadow: inset 0 2px 20px #ffabf14d;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  gap: 5px;
  height: min-content;
  justify-content: flex-start;
  min-width: 300px;
  height: 120px;
  overflow: hidden;
  position: relative;
  will-change: var(--framer-will-change-override, transform);
  border: var(--border-top-width) var(--border-style) var(--border-color);
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Existing domain card styles */
.domain-card {
  transition: transform 0.3s, opacity 0.3s;
  border-radius: 20px;
  color: #fff;
  padding: 30px;
  font-size: 14px;
  font-weight: 400;
  --border-bottom-width: 1px;
  --border-color: #ff6eff;
  --border-left-width: 1px;
  --border-right-width: 1px;
  --border-style: solid;
  --border-top-width: 1px;
  box-shadow: inset 0 2px 36px #ffabf14d;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  position: relative;
  will-change: var(--framer-will-change-override, transform);
  border: var(--border-top-width) var(--border-style) var(--border-color);
  min-width: 250px;
  opacity: 0;
  transform: translateY(50px);
}
.domain-card.reveal:hover {
  transform: translateY(-20px);
}

.domain-icon {
  width: 50px;
}

.domain-title {
  color: #ffbfff;
  font-size: 20px;
}

.domain-description {
  line-height: 26px;
  letter-spacing: 0.01rem;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}

/* Reveal animation */
@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.domain-card.reveal {
  opacity: 1;
  transform: translateY(0);
  animation: reveal 0.6s ease-out;
}

/* Partners Css */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.slider-track {
  display: flex;
  animation: slide 10s linear infinite; /* Adjust duration as needed */
  gap: 100px;
}

.partner-image {
  flex-shrink: 0;
  width: 130px; /* Adjust size as needed */
  margin-right: 20px; /* Adjust spacing as needed */
}

/* Full Details */
.full_details {
  opacity: 0;
  animation: fade-in-right ease 0.8s forwards;
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
